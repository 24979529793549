import React from "react"
// import { graphql, Link, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import coachingbloks from "../images/coachingbloks.jpeg"
// import Img from "gatsby-image"
import manandwoman from "../images/manandwoman.jpg"
import plannerfoto from "../images/planner-2.jpg"

const SecondPage = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     keurmerk: file(relativePath: { eq: "keurmerk.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 400) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      <SEO
        title="professionele coaching voor bedrijven "
        description="Professionele coaching met de Zipperr® methode is een gestructureerd en doelgericht proces waarbij op interactieve wijze effectief gedrag wordt gestimuleerd"
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${coachingbloks})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Wat kun je verwachten van een professionele coach?
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Wat onze coaches bieden</p>
        </div>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Een professionele coach boekt resultaten met jou of met jouw
            medewerker(s).{" "}
          </li>
          <li>
            Een professionele coach realiseert duurzame gedragsveranderingen.
          </li>
          <li>
            Een professionele coach is transparant en kan op een plezierige
            wijze samenwerken.
          </li>
        </ul>
      </div>

      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>Wat is professionele coaching?</h2>

          <p>
            Professionele coaching is een gestructureerd en doelgericht proces,
            waarbij de coach op interactieve wijze de gecoachte aanzet tot
            effectief gedrag door:
          </p>
          <ul style={{ listStyleType: "circle" }}>
            <li>bewustwording en persoonlijke groei van de gecoachte</li>
            <li>het vergroten van het zelfvertrouwen van de gecoachte</li>
            <li>
              het exploreren, ontwikkelen en toepassen van eigen mogelijkheden
            </li>
            <li>
              Coach en gecoachte nemen ieder hun verantwoordelijkheid voor het
              proces.
            </li>
          </ul>
          <p>
            Onder gecoachte kan ook worden verstaan: een team dat als eenheid
            wordt gecoacht.
          </p>
          <p className={"btn-container"}>
            <Link
              to={"/traject"}
              className="btn primary-lg round expand-on-hover"
            >
              Trajecten van Zipperr®
            </Link>
          </p>
        </div>
        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${plannerfoto})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
      <div className={"containerPad flexy"}>
        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${manandwoman})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transform: "scaleX(-1)",
          }}
        />

        <div className={"half block"}>
          <h2>Individuele coaching</h2>
          <p>
            de Zipperr® Methode activeert jouw onderbewuste en legt een dusdanig
            fundament onder de gedragsverandering(en) dat deze direct
            geactiveerd worden en blijvend zijn. Teamcoaching: de Zipperr®
            Methode haalt het maximale rendement uit teams door het vertrouwen
            te vergroten en de samenwerking optimaal te stimuleren.
          </p>
          <p className={"btn-container"}>
            <Link
              to={"/traject"}
              className="btn primary-lg round expand-on-hover"
            >
              Trajecten van Zipperr®
            </Link>
          </p>
        </div>
      </div>
      {/* <div className="highlightedText containerPadModified">
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Keurmerk voor coaching
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">EIA</p>
        </div>
        <div className={"gridlayout"}>
          <p className={"footertext"}>
            Ten behoeve van klanten en opdrachtgevers respecteert en hanteert
            Zipperr® de ethische gedragscode en de klachtenprocedure van NOBCO,
            De Nederlandse Orde van Beroepscoaches. Zipperr® is gecertificeerd
            met het{" "}
            <a href="https://www.nobco.nl/keurmerk/eia-keurmerk-coaching">
              Europese EIA-keurmerk
            </a>
          </p>
          <div className={"footerimg"}>
            <Img
              fluid={data.keurmerk.childImageSharp.fluid}
              objectFit="cover"
              alt="EIA Keurmerk"
              className={"keurmerkpos"}
            />
          </div>
        </div>
      </div> */}
    </Layout>
  )
}
export default SecondPage
